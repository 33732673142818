import { getAppConfig } from '@services/config';
import { apiHttpService, externalHttpService, httpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { QUOTE_TYPES } from '@app-types/cart';
import { ApiShippingType, ApiStockType, ShippingType, StockType } from '@app-types/product-shipping';

const appConf = getAppConfig();

export const getProductStock = async (gencode: string): Promise<StockType> => {
    return externalHttpService
        .get<ApiStockType>(`${appConf.smartTraffik.url}/product/${gencode}/status`, undefined, {
            headers: { 'api-key': appConf.smartTraffik.apikey },
            ttl: parseInt(getAppConfig().cache.ttl.productsStocks),
            tags: [CACHE_TAGS.PRODUCTS_STOCK, CACHE_TAGS.PRODUCTS],
        })
        .then((response): StockType => {
            if (response) {
                const { active, activeEreservation, activePreReservation } = response;
                return {
                    isEresa: active === '1' && activeEreservation === '1' && activePreReservation !== '1',
                    isPreresa: active === '1' && activePreReservation === '1',
                };
            }
            throw new Error('Empty response');
        })
        .catch((error) => {
            console.error(`Fetch ${gencode} stock status failed`, error);
            return {
                isEresa: false,
                isPreresa: false,
            };
        });
};

export const getDeliveryModes = async (
    gencode: string,
    isPro = false,
    userZipCode = '75000',
): Promise<ShippingType> => {
    const deliveryParams = {
        typeParcours: QUOTE_TYPES.ACQUISITION,
        typeClient: isPro ? 'PRO' : 'GP',
        gencodes: [gencode],
        adresse: { codePostal: userZipCode },
    };

    return apiHttpService
        .post<ApiShippingType>(`${appConf.sapi.url}/ventes/paniers/modes-livraison`, deliveryParams, {
            ttl: parseInt(getAppConfig().cache.ttl.products),
            tags: [CACHE_TAGS.PRODUCTS],
        })
        .then((response): ShippingType => {
            if (response) {
                return response.modesLivraison.map((mode) => ({
                    id: mode.gencode,
                    estimatedDeliveryDate: mode.dateLivraisonEstimee,
                    price: mode.prix,
                }));
            }
            throw new Error('Empty response');
        })
        .catch((error) => {
            console.error(`Fetch ${gencode} delivery modes failed`, error);
            return [];
        });
};

export const createStockAlert = async (mail: string, productId: string): Promise<unknown> => {
    return httpService.get<ApiShippingType>(
        `${appConf.productAlert.url}/add/stock/`,
        {
            mail,
            product_id: productId,
        },
        {
            ttl: parseInt(getAppConfig().cache.ttl.products),
            tags: [CACHE_TAGS.PRODUCTS],
        },
    );
};
