import { formatTagText, sendTracking } from '@helpers/tracking';

export function sendProductTabsTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_selection',
        category: 'Onglets_Fiche_produit',
        name: formatTagText(action),
    });
}

export function sendProductPortabilityTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_selection',
        category: 'Infos_telephone_numero',
        name: formatTagText(action),
    });
}

export function sendProductConvergenceTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_selection',
        category: 'Infos_convergence',
        name: formatTagText(action),
    });
}

export function sendProductPhoneFundingTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_selection',
        category: 'Infos_telephone_etalement_paiement',
        name: formatTagText(action),
    });
}

export function sendProductPhonePriceRecapTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_affichage',
        category: 'Recap_prix_telephone',
        name: formatTagText(action),
    });
}

export function sendProductPlanPriceRecapTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_affichage',
        category: 'Recap_prix_forfait',
        name: formatTagText(action),
    });
}

export function sendProductOtherPlansTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_redirection',
        category: 'Recap_prix_autres_forfaits',
        name: formatTagText(action),
    });
}

export function sendProductPreviewTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_affichage',
        category: 'Barre_visuels',
        name: 'icone_visuels',
    });
}

export function sendProductStockTracking(isRenewal: boolean, action: string) {
    sendTracking(isRenewal, {
        eCommerceStep: 'Fiche_produit',
        action: 'Clic_affichage',
        category: 'Stock_disponibilite',
        name: formatTagText(action),
    });
}
