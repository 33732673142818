import { ReviewResponseType, ReviewType, ReviewUser, ReviewUserGender, ReviewsInfoType } from '@app-types/review';

const transformToReviewType = (data: ReviewResponseType): ReviewType => {
    return {
        note: Number(data.rate),
        title: data.product_review_title,
        shortMessage: data.review.slice(0, 280),
        message: data.review,
        created_at: new Date(data.review_date),
        user: getUser(data),
        isRecommended: data.product_review_recommandation_ami.toLowerCase() === 'oui',
        recommendedFor: data.product_review_recommandation_telephone,
        syndication: {
            image: data.medias,
            name: data.brand_name,
        },
    };
};

const getUser = (data: ReviewResponseType): ReviewUser => {
    const user: ReviewUser = {
        login: data.firstname,
        isVerified: true,
        ageLabel: data.brand_review_age,
    };

    if (data.product_review_sexe) {
        user.gender = data.product_review_sexe === 'Homme' ? ReviewUserGender.MALE : ReviewUserGender.FEMALE;
    }

    return user;
};

export const mapToReviewInfo = (data: ReviewResponseType[], offset: number, limit: number): ReviewsInfoType => {
    const reviewInfo: ReviewsInfoType = {
        note: 0,
        reviewCount: 0,
        averages: {},
        reviews: [],
    };
    const reviewCount = data.length;
    let note = 0;
    let averageAuto = 0;
    let averageUse = 0;
    let averageDesign = 0;
    let averageMulti = 0;

    reviewInfo.reviewCount = reviewCount;

    const reviews = data.slice(offset, limit + offset);

    // calculate averages
    data.forEach((reviewData) => {
        note += +reviewData.rate;
        averageAuto += +reviewData.product_review_autonomie;
        averageUse += +reviewData.product_review_facilitedutilisation;
        averageDesign += +reviewData.product_review_design;
        averageMulti += +reviewData.product_review_qualite_appareil2;
    });

    // process reviews
    reviewInfo.reviews = reviews.map((review) => transformToReviewType(review));

    reviewInfo.note = calculateAverage(note, reviewCount);

    reviewInfo.averages = {
        multimedia: { label: 'Multimedia', note: calculateAverage(averageMulti, reviewCount) },
        use: { label: 'Utilisation', note: calculateAverage(averageUse, reviewCount) },
        autonomy: { label: 'Autonomie', note: calculateAverage(averageAuto, reviewCount) },
        design: { label: 'Design', note: calculateAverage(averageDesign, reviewCount) },
    };

    return reviewInfo;
};

export const calculateAverage = (score: number, count: number): number => {
    return Math.round((score * 10) / count) / 10;
};
