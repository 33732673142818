'use client';

import { ReactNode } from 'react';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Container,
    TrilogyColor,
} from '@bytel/trilogy-react-ts';

import { SSIBlock } from '@components/ssi-block';

type LegalsProps = {
    id: string;
    url: string;
};

function LegalsContainer({ id, children }: { id: string; children: ReactNode }) {
    return (
        <Container fluid background={TrilogyColor.WHITE} data-cy='legals'>
            <Accordion>
                <AccordionItem id={`legals-${id}`}>
                    <AccordionHeader toggle>Mentions légales</AccordionHeader>
                    <AccordionBody>{children}</AccordionBody>
                </AccordionItem>
            </Accordion>
        </Container>
    );
}

export function Legals({ id, url }: LegalsProps) {
    if (!url) {
        return null;
    }

    return (
        <SSIBlock
            tagId={id}
            url={`/partials/mentions_legales/emplacements/${url}`}
            container={LegalsContainer}
            containerProps={{ id }}
        />
    );
}
