export enum DiscountEnumType {
    ODR = 'ODR',
    FIX = 'FIX',
    PERCENT = 'PERCENT',
    STANDARD = 'PROMOTION_STANDARD',
    VARIABLE_AMOUNT = 'MONTANT_VARIABLE',
    COUPON = 'PROMOTION_COUPON',
}

export type DiscountType = {
    type: DiscountEnumType;
    value: number;
    discount: number;
    duration: number;
    marketingTypes: string[];
    isIncitation?: boolean;
    isDiffered?: boolean;
    fanion?: string;
    pennant?: string;
};
