import { getAppConfig } from '@services/config';
import { apiHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { UserType } from '@app-types/user';

class SapiRepository {
    url: string;

    public constructor(url: string) {
        this.url = url;
    }

    public getProductByGencode<T>(gencode: string): Promise<T> {
        return this.getProducts(`/${gencode}`);
    }

    public async getProductByUrl<T>(url: string, withParent: boolean = false): Promise<T> {
        const params = { attribute: 'url_key', parent: withParent ? 'true' : 'false' };

        return this.getProducts(`/${url}?${new URLSearchParams(params)}`);
    }

    public async getProductsByGencodes<T>(gencodes: string[], user?: UserType): Promise<T> {
        return this.getProducts(`?listGencode=${gencodes.join(',')}`, user);
    }

    public async getProducts<T>(url: string, user?: UserType): Promise<T> {
        return apiHttpService
            .get<T>(`${this.url}/ventes/produits${url}`, undefined, {
                ttl: parseInt(getAppConfig().cache.ttl.productDetail),
                tags: [CACHE_TAGS.PRODUCT_DETAIL],
                user,
            })
            .catch((error) => {
                throw new Error(`Could not fetch SAPI`, error);
            });
    }
}

export const sapiRepository = new SapiRepository(getAppConfig().sapi.url);
