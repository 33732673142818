'use client';

import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { getServerSession, serverSignIn } from '@actions/get-session';

import { signIn } from '@helpers/authentication';
import { getClientCookies } from '@helpers/cookies-client';

import { isPast } from 'date-fns';

export function TriggerLogin() {
    useEffect(() => {
        getServerSession().then((session) => {
            const isSessionExpired = session?.expires !== undefined && isPast(session.expires);

            if ((!session && getClientCookies('SSO_ACTIVE') !== '') || isSessionExpired) {
                serverSignIn();
            }
        });
    }, []);

    return null;
}

export function ForceLogin() {
    const { status, data: session } = useSession();

    useEffect(() => {
        const isSessionExpired = session?.user?.exp && isPast(session.user.exp * 1000);

        if (status === 'unauthenticated' || isSessionExpired) {
            signIn();
        }
    }, []);

    return null;
}
