export function stickersInPreviousPages(page: number, size: number, stickersPosition: number[]): number {
    if (page === 1) {
        return 0;
    }

    page--;

    const max = page * size;

    stickersPosition = stickersPosition.filter((position) => position < max);

    return stickersInCurrentPage(page, size, stickersPosition) + stickersInPreviousPages(page, size, stickersPosition);
}

export function stickersInCurrentPage(page: number, size: number, stickersPosition: number[]): number {
    const max = page * size;

    return stickersPosition.filter((position) => position >= max - size && position < max).length;
}

export function getPagination(
    page: number,
    size: number,
    stickersPosition: number[],
): { limit: number; offset: number } {
    const offset = (page - 1) * size - stickersInPreviousPages(page, size, stickersPosition);

    const limit = size - stickersInCurrentPage(page, size, stickersPosition);

    return { limit, offset };
}
