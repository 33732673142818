'use client';

import type { ProductChildFull, ProductParentFull } from '@app-types/product';

import { getSimilarProductsLink } from '@helpers/product';

import type { BreadcrumbList, Product } from 'schema-dts';

export function getPhoneStructuredData(
    pathname: string,
    product: ProductParentFull,
    currentProduct: ProductChildFull,
    price?: number,
): Product {
    const data: Product = {
        '@type': 'Product',
        sku: currentProduct.sku,
        mpn: product.sku,
        gtin13: currentProduct.gencode,
        name: currentProduct.title,
        image: currentProduct.medias.map((media) => `https://www.bouyguestelecom.fr/${media}`),
        description: currentProduct.metaDescription,
        brand: {
            '@type': 'Brand',
            name: product.manufacturer,
            url: `https://www.bouyguestelecom.fr${getSimilarProductsLink(currentProduct, false)}`,
        },
        category: 'smartphone',
        offers: {
            '@type': 'Offer',
            url: `https://www.bouyguestelecom.fr${pathname}`,
            price: price?.toFixed(2),
            itemCondition: /recondition/i.test(product.name) ? 'RefurbishedCondition' : 'NewCondition',
            availability: currentProduct.stockDetails.stock.structuredData,
            priceCurrency: 'EUR',
            seller: {
                '@type': 'Organization',
                name: 'Bouygues Telecom',
            },
        },
    };

    if (product.reviews) {
        data.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: product.reviews.note,
            ratingCount: product.reviews.reviewCount,
            bestRating: 5,
            worstRating: 1,
        };
    }

    return data;
}

export function getBreadcrumbStructuredData(
    pathname: string,
    breadcrumb: { label: string; href?: string }[],
): BreadcrumbList {
    return {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumb.map(({ label, href }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: label,
            item: {
                '@id': `https://www.bouyguestelecom.fr${href ?? pathname}`,
                '@type': 'Thing',
            },
        })),
    };
}
