import { calculatePrice } from '@bytel/product-wall/helpers';

import { PlanPrice } from '@services/products';

import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { ProductChildFull } from '@app-types/product';

import { getDiscountValue } from '@helpers/product';

export type PricesDetails = {
    isTaxExcluded: boolean;
    productPrices: {
        marketingAmount: number;
        fundingMonthlyAmount: number;
        todayPrice: number;
        odrRefund: number;
    };
    planPrices: {
        mainAmount: number;
        forever: number;
        promoDuration?: number;
    };
    funding?: {
        type: FundingEnumType;
        installmentCount: number;
        label: string;
    };
};

export function getPricesDetails(
    currentProduct?: ProductChildFull,
    selectedFunding?: FundingType,
    planPrice?: PlanPrice,
    isPro = false,
): PricesDetails {
    const isTaxExcluded = isPro;

    const fixedPriceTTC =
        currentProduct?.details?.fundings?.find((f) => f.type === FundingEnumType.COMPTANT)?.amountToFinance ?? 0;
    const odrRefundTTC = getDiscountValue(currentProduct?.details?.discounts ?? [], DiscountEnumType.ODR) ?? 0;

    const productMainAmountTTC =
        (selectedFunding ? selectedFunding.initialContributionWithOdr : fixedPriceTTC - odrRefundTTC) ?? 0;

    const productMainAmountToShow = calculatePrice(productMainAmountTTC, isTaxExcluded);

    const todayProductPriceTTC =
        (selectedFunding?.initialDeposit ? selectedFunding.initialDeposit : fixedPriceTTC) ?? 0;
    const todayProductPrice = calculatePrice(todayProductPriceTTC, isTaxExcluded);

    const fundingMonthlyAmountTTC = selectedFunding?.monthlyAmount ?? 0;
    const fundingMonthlyAmountToShow = calculatePrice(fundingMonthlyAmountTTC, isTaxExcluded);

    const foreverPlanPrice = calculatePrice(planPrice?.forever ?? 0, isTaxExcluded);
    const planPriceToShow = calculatePrice(planPrice?.final ?? 0, isTaxExcluded);
    const odrRefund = calculatePrice(odrRefundTTC, isTaxExcluded) * -1;

    return {
        isTaxExcluded,
        productPrices: {
            marketingAmount: productMainAmountToShow,
            fundingMonthlyAmount: fundingMonthlyAmountToShow,
            todayPrice: todayProductPrice,
            odrRefund,
        },
        planPrices: {
            mainAmount: planPriceToShow,
            forever: foreverPlanPrice,
            promoDuration: planPrice?.promoDuration,
        },
        ...(selectedFunding && {
            funding: {
                type: selectedFunding.type,
                installmentCount: selectedFunding.installmentCount,
                label: selectedFunding.label,
            },
        }),
    };
}
