import { MarketingsState, PartialMarketingsState } from '@hooks/use-marketings';

import { MarketingType } from '@constants/plan';

export function updatePromos(
    marketings: MarketingsState,
    setMarketings: (marketings: PartialMarketingsState) => MarketingsState,
    sendTracking: (isRenewal: boolean, text: string) => void,
    isRenewal: boolean,
) {
    return (set: boolean, valuesToUpdate: MarketingType[]) => {
        const newMarketings = valuesToUpdate.reduce((acc, valueToUpdate) => {
            return {
                ...acc,
                [valueToUpdate]: set,
            };
        }, {});
        setMarketings({
            ...marketings,
            ...newMarketings,
        });
        sendTracking(isRenewal, set ? 'Oui' : 'Non');
    };
}
