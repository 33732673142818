export type AverageResponseType = Record<string, { rate: string; count: string }[]>;

export type ReviewResponseType = {
    rewarded_review: string;
    count_helpful_yes: string;
    firstname: string;
    description: string;
    order_ref: string;
    rate: string;
    review: string;
    email: string;
    product_review_recommandation_ami: string;
    product_review_temps_utilisation: string;
    info10: string;
    count_helpful_no: string;
    hide_personnal_data: string;
    product_review_design: string;
    url_product: string;
    brand_name: string;
    product_review_facilite_installation: string;
    lastname: string;
    info9: string;
    info8: string;
    order_date: string;
    product_review_qualite_produit: string;
    medias: string;
    info7: string;
    id_review_product: string;
    review_date: string;
    info6: string;
    id_product: string;
    brand_review_age: string;
    product_review_facilitedutilisation: string;
    product_review_recommandation_telephone: string;
    info1: string;
    product_review_autonomie: string;
    id_review: string;
    product_review_title: string;
    info5: string;
    category: string;
    sign_helpful: string;
    info4: string;
    publish_date: string;
    info3: string;
    info2: string;
    product_review_qualite_appareil2: string;
    product_review_profil: string;
    product_review_sexe: string;
};

export type ReviewsResponseType = {
    reviews: ReviewResponseType[];
}[];

export type ReviewAverageType = { gencode: string; rate: number; count: number };

export enum ReviewUserGender {
    MALE = 'Homme',
    FEMALE = 'Femme',
}

export type ReviewUser = {
    login: string;
    gender?: ReviewUserGender;
    ageLabel: string;
    isVerified: boolean;
};

export type ReviewType = {
    note: number;
    title: string;
    shortMessage: string;
    message: string;
    created_at: Date;
    user: ReviewUser;
    isRecommended: boolean;
    syndication: {
        image: string;
        name: string;
    };
    recommendedFor: string;
    pros?: string;
    cons?: string;
};

export type ReviewsInfoType = {
    note: number;
    reviewCount: number;
    averages: Record<string, { label: string; note: number }>;
    reviews: ReviewType[];
};
