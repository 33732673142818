export const isFirstLetterVowel = (word: string): boolean => {
    return word.match(/^[aeiou]/i) !== null;
};

export const capitalize = (s?: string): string => {
    if (!s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const numberWithComma = (num: number): string => {
    return num.toString().replace('.', ',');
};
