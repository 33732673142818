import { getSessionVariable, removeSessionVariable, setSessionVariable } from '@services/storage';

import { RecoveryCriteriaResponsesType, RecoveryProductType, SessionRecoveryProduct } from '@app-types/recovery';

import { RecoveryStatus, RecoveryStep } from '@constants/recovery';

const SESSION_KEY = 'mobile_recovery_current_product';

export type ReturnSessionRecoveryProduct = {
    [RecoveryStep.NOT_STARTED]: RecoveryProductType | undefined;
    [RecoveryStep.CHOICE_NO]: RecoveryProductType | undefined;
    [RecoveryStep.SEARCH]: RecoveryProductType | undefined;
    [RecoveryStep.FORM]: RecoveryCriteriaResponsesType['items'] | undefined;
    [RecoveryStep.CONFIRMATION]: { amount: number; iban: string } | undefined;
    [RecoveryStep.CONFIRMED]: undefined;
};

export function getSessionRecoveryStep(step: RecoveryStep): ReturnSessionRecoveryProduct[RecoveryStep] | undefined {
    const session = getSessionVariable(SESSION_KEY);
    if (!session) {
        return undefined;
    }

    const recoveryProduct = JSON.parse(session) as SessionRecoveryProduct;
    switch (step) {
        case RecoveryStep.SEARCH:
            return recoveryProduct.recovery.product;
        case RecoveryStep.FORM:
            return recoveryProduct.recovery.criterias.items;
        case RecoveryStep.CONFIRMATION:
            return recoveryProduct.recovery.estimation && recoveryProduct.product
                ? {
                      amount: recoveryProduct.recovery.estimation.amount,
                      iban: recoveryProduct.iban,
                  }
                : undefined;
        default:
            return undefined;
    }
}

export function getSessionRecoveryLastStep(): RecoveryStep {
    const session = getSessionVariable(SESSION_KEY);
    if (!session) {
        return RecoveryStep.SEARCH;
    }

    const recoveryProduct = JSON.parse(session) as SessionRecoveryProduct;

    if (recoveryProduct.status === RecoveryStatus.COMPLETED) {
        return RecoveryStep.CONFIRMED;
    }

    if (recoveryProduct.recovery.estimation) {
        return RecoveryStep.CONFIRMATION;
    }

    if (recoveryProduct.recovery.criterias && recoveryProduct.recovery.criterias.items.length > 0) {
        return RecoveryStep.FORM;
    }

    return RecoveryStep.SEARCH;
}

type SetSessionRecoveryStepInput =
    | RecoveryProductType
    | RecoveryCriteriaResponsesType['items']
    | SetSessionRecoveryConfirmationInput
    | string;

export type SetSessionRecoveryConfirmationInput = {
    estimation: { amount: number };
    recoveryId: string;
    questions: { question: string; reponse: { value: string } }[];
};

export function setSessionRecoveryStep(step: RecoveryStep, value: SetSessionRecoveryStepInput): void {
    let recoveryProduct: SessionRecoveryProduct;
    if (step === RecoveryStep.SEARCH) {
        recoveryProduct = {
            status: RecoveryStatus.UNFINISHED,
            recovery: {
                product: value as RecoveryProductType,
                criterias: {
                    items: [],
                    id: (value as RecoveryProductType).data.id,
                },
            },
            product: {
                item: {
                    url: `${window.location.origin}${window.location.pathname}`,
                },
            },
            iban: '',
        };
    } else {
        recoveryProduct = JSON.parse(getSessionVariable(SESSION_KEY) as string) as SessionRecoveryProduct;
        switch (step) {
            case RecoveryStep.FORM:
                recoveryProduct.recovery.criterias.items = value as RecoveryCriteriaResponsesType['items'];
                recoveryProduct.status = RecoveryStatus.UNFINISHED;
                break;
            case RecoveryStep.CONFIRMATION:
                recoveryProduct.recovery.estimation = {
                    amount: (value as SetSessionRecoveryConfirmationInput).estimation.amount,
                    id: (value as SetSessionRecoveryConfirmationInput).recoveryId,
                    items: {
                        criteresReprise: (value as SetSessionRecoveryConfirmationInput).questions,
                    },
                };
                recoveryProduct.recovery_id = (value as SetSessionRecoveryConfirmationInput).recoveryId;
                recoveryProduct.status = RecoveryStatus.UNFINISHED;
                break;
            case RecoveryStep.CONFIRMED:
                recoveryProduct.status = RecoveryStatus.COMPLETED;
                recoveryProduct.iban = value as string;
                break;
            default:
                break;
        }
    }
    setSessionVariable(SESSION_KEY, JSON.stringify(recoveryProduct));
}

export function setSessionRecoveryBonus(bonus?: number): void {
    const session = getSessionVariable(SESSION_KEY);
    if (!session) {
        return undefined;
    }
    const recoveryProduct = JSON.parse(session) as SessionRecoveryProduct;
    recoveryProduct.product.bonus = bonus;
    setSessionVariable(SESSION_KEY, JSON.stringify(recoveryProduct));
}

export function clearSessionRecoveryProduct() {
    removeSessionVariable(SESSION_KEY);
}
