export enum PROCESS_TYPE {
    'WEB' = 'portailvente-web-default',
    'WALL_PHONE' = 'portailvente-web-wall-phone',
    'WALL_PHONE_RENEW' = 'portailvente-web-wall-phone-renew',
    'PRODUCT' = 'portailvente-web-product',
    'PRODUCT_RENEW' = 'portailvente-web-product-renew',
}

class AppContext {
    private process: PROCESS_TYPE = PROCESS_TYPE.WEB;

    public setProcess(process: PROCESS_TYPE): void {
        this.process = process;
    }

    public getProcess(): string {
        return this.process.toString();
    }
}
export const appContext = new AppContext();
