'use client';

import { Price as PriceTrilogy } from '@bytel/trilogy-react-ts';

export const Price: typeof PriceTrilogy = ({ amount, showCents, inline = true, className = 'is-6', ...args }) => {
    return (
        <PriceTrilogy
            showCents={showCents ? showCents : !Number.isInteger(amount)}
            inline={inline}
            amount={amount}
            className={className}
            {...args}
        />
    );
};
