import { getAppConfig } from '@services/config';
import { axiosLoggerService } from '@services/loggers';

import axios, { AxiosRequestConfig } from 'axios';
import { AxiosCacheInstance, InternalCacheRequestConfig, setupCache } from 'axios-cache-interceptor';
import { HttpProxyAgent } from 'http-proxy-agent';
import { HttpsProxyAgent } from 'https-proxy-agent';

const config = getAppConfig();

export interface BytelAxiosRequestConfig<R = unknown, D = unknown> extends InternalCacheRequestConfig<R, D> {
    metadata: {
        startDate: number;
    };
}

export function axiosInstanceFactory(external = false): AxiosCacheInstance {
    const proxy = config.proxy.url;

    const axiosConfig: AxiosRequestConfig = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (external && proxy) {
        axiosConfig.proxy = false;
        axiosConfig.httpAgent = new HttpProxyAgent(proxy);
        axiosConfig.httpsAgent = new HttpsProxyAgent(proxy);
    }

    const defaultAxiosInstance = axios.create(axiosConfig);

    const cacheAxios = setupCache(defaultAxiosInstance, {
        ttl: 60 * 60 * 1000,
        methods: ['get', 'post'],
        interpretHeader: false,
        cachePredicate: {
            statusCheck: (status) => [200, 201, 203, 300, 301, 302, 404, 405, 410, 414, 501].includes(status),
        },
    });

    cacheAxios.interceptors.request.use((req) => {
        // custom axios options config
        const bytelConfig = req as BytelAxiosRequestConfig;
        bytelConfig.metadata = {
            startDate: Date.now(),
        };

        return bytelConfig;
    });

    cacheAxios.interceptors.response.use((res) => {
        axiosLoggerService.logHttpResponse(res);
        return res;
    });

    return cacheAxios;
}
