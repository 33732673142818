import { axiosLoggerService } from '@services/loggers';

import { AxiosResponse } from 'axios';
import { AxiosCacheInstance, CacheRequestConfig } from 'axios-cache-interceptor';

import { HttpConfig, HttpService } from './http';

export class AxiosService extends HttpService {
    private httpInstance: AxiosCacheInstance;

    constructor(axiosInstance: AxiosCacheInstance) {
        super();
        this.httpInstance = axiosInstance;
    }

    public async get<T>(url: string, data: object = {}, httpConfig: HttpConfig = {}): Promise<T> {
        const config = this.initConfig(httpConfig);
        config.params = data;
        return this.httpInstance
            .get(url, config)
            .then((response) => this.processResponse<T>(response))
            .catch((error) => {
                axiosLoggerService.logHttpError(error);
                throw new Error(error);
            });
    }

    public async post<T>(url: string, data?: Record<string, string>, httpConfig: HttpConfig = {}): Promise<T> {
        return this.httpInstance
            .post(url, data, this.initConfig(httpConfig))
            .then((response) => this.processResponse<T>(response))
            .catch((error) => {
                axiosLoggerService.logHttpError(error);
                throw new Error(error);
            });
    }

    protected processResponse<T>(response: AxiosResponse): T {
        return response.data;
    }

    protected initConfig(httpConfig: HttpConfig = {}): CacheRequestConfig {
        const headersObject = httpConfig.headers || {};
        delete headersObject['host'];

        const config: CacheRequestConfig = {
            headers: headersObject,
            signal: httpConfig?.controller ? httpConfig.controller.signal : undefined,
        };

        // default cache 1h
        config.cache = {
            ttl: 3600 * 1000,
        };

        if (httpConfig?.ttl !== undefined) {
            if (httpConfig.ttl === 0) {
                config.cache = false;
            } else {
                config.cache = {
                    ttl: httpConfig.ttl * 1000,
                };
            }
        }

        return config;
    }
}
