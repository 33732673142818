import { SortOptionType } from '@bytel/product-wall';
import { FilterType, ObjectFilterType, TopFilterType } from '@bytel/product-wall/types';

export enum WallType {
    PHONE = 'phone',
    ACCESSORY = 'accessory',
    OFFERWALL = 'plan_premium,plan_simo',
}

export type WallConfigType = {
    baseUrl: string;
    baseTitle: string;
    productType: string;
    breadcrumb: {
        baseText: string;
        title: string;
    };
    productByPage: number;
    minProductsToIndex: number;
    filters: FilterType[];
    filtersObject: Record<string, ObjectFilterType>;
    sorter: Record<string, SortOptionType>;
    topFilters?: Record<string, TopFilterType>;
    filtersOrder: string[];
};

export type WallConfigFileType = Record<WallType, Omit<WallConfigType, 'filtersObject'>>;

export type EligibilityConfigType = {
    technoToDisplayIfAlone?: string;
};
