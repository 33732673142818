import { StorageCartType } from '@app-types/api/cart';
import { QUOTE_TYPES, QuoteType } from '@app-types/cart';

import { quoteMapper } from '@helpers/cart';

export function getCart(isRenewal: boolean = false): QuoteType | undefined {
    const cartStorage = localStorage.getItem('checkout_cart');
    const cart = JSON.parse(cartStorage || '{}') as StorageCartType;
    if (cart) {
        const quoteMobile = isRenewal ? QUOTE_TYPES.RENEWAL : QUOTE_TYPES.ACQUISITION;
        const checkoutQuote = cart.data?.quotes.find((quote) => quote.context.id === quoteMobile);
        if (checkoutQuote) {
            return quoteMapper(checkoutQuote);
        }
    }
    return undefined;
}

export function isPro(isRenewal: boolean = false): boolean {
    const cartStorage = localStorage.getItem('checkout_cart');
    const cart = JSON.parse(cartStorage || '{}') as StorageCartType;

    const isCartPro = cart.data?.isPro ?? false;
    const hasOneProductPro = getCart()?.products.some((product) => product.isPro) ?? false;

    return (hasOneProductPro || isCartPro) && !isRenewal;
}
