import { getAppConfig } from '@services/config';
import { apiHttpService } from '@services/http';
import { logger } from '@services/loggers';

import { contractsRepository } from '@repositories/contracts';

import { ContractType } from '@app-types/contract';
import { Oauth2UserType } from '@app-types/oauth2';
import { UserType } from '@app-types/user';

const appConfig = getAppConfig();

export const getSignedContracts = async (sub: Oauth2UserType['sub'], user: UserType): Promise<ContractType[]> => {
    try {
        return await contractsRepository.getSignedContracts(sub, user);
    } catch (e) {
        logger.error(`Error while fetching signed contracts of "${sub}"`, e);
        return [];
    }
};

export const getContract = async (user: UserType, id: ContractType['id']): Promise<ContractType | undefined> => {
    type ResponseType = {
        _links: {
            titulaire: {
                href: string;
            };
        };
    };

    let sub = user.sub;

    if (user.user_type === 'CDC') {
        const headers = new Headers();
        headers.set('X-Oauth2-Habilit', 'DRC_CDC_SP');
        headers.set('X-Oauth2-Scope', 'ContratConsult');

        const response = await apiHttpService.get<ResponseType>(`${appConfig.contrats.url}/contrats/${id}`, undefined, {
            headers: Object.fromEntries(headers.entries()),
            ttl: parseInt(appConfig.cache.ttl.products),
            user,
        });

        sub = response?._links?.titulaire?.href.split('/')[2] as string;
    }

    return await contractsRepository.getContract(sub, id, user);
};
