import { Session } from 'next-auth';

import { ActiveFiltersType } from '@bytel/product-wall';
import { TagSymbol } from '@bytel/product-wall/types';
import { VariantState } from '@bytel/trilogy-react-ts/lib/objects/facets/Variant';

import { getAppConfig } from '@services/config';
import { getContract } from '@services/contract';
import { apiHttpService, httpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';
import { getDefaultPlan } from '@services/products';
import { reviewServices } from '@services/review';

import { sapiRepository } from '@repositories/sapi';
import { sapicRepository } from '@repositories/sapic';

import { WallMarketing, WallMarketingItem } from '@app-types/api/palpatine';
import {
    SapiMergedSapicType,
    SapiProductChildTypeWithSapicInfo,
    SapiProductType,
    SapiResponseType,
} from '@app-types/api/sapi';
import {
    SapicCategoryId,
    SapicCustomerCategory,
    SapicFundingMode,
    SapicPriceForFilter,
    SapicPriceForSort,
} from '@app-types/api/sapic';
import { QUOTE_TYPES } from '@app-types/cart';
import { WallType } from '@app-types/config';
import { ContractType } from '@app-types/contract';
import { FaiType } from '@app-types/plan';
import { Product } from '@app-types/product';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';
import { UserType } from '@app-types/user';

import { transformChildProductToParentProduct } from '@product-detail/helpers';
import { z } from 'zod';

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export type GetProductOptionType = {
    category?: SapicCategoryId;
    sort?: string;
    pageNumber?: number;
    limit?: number;
    clientCategory?: SapicCustomerCategory; // @TODO: replace by isPro
    fundingMode?: SapicFundingMode;
    priceFilter?: SapicPriceForFilter;
    priceSort?: SapicPriceForSort;
    filters?: ActiveFiltersType;
    plan?: string;
    autoComplete?: string;
    renewal?: boolean;
    contract?: string;
    stickers?: string[];
    gencodes?: string[];
    isPro?: boolean;
    withDetails?: boolean;
    filter?: string;
};

const GetWallProductApiSchema = z.object({
    sort: z.string().optional(),
    clientCategory: z.nativeEnum(SapicCustomerCategory).optional(),
    fundingMode: z.nativeEnum(SapicFundingMode).optional(),
    priceFilter: z.nativeEnum(SapicPriceForFilter).optional(),
    priceSort: z.nativeEnum(SapicPriceForSort).optional(),
    filters: z.record(z.string().array()).optional(),
    plan: z.string().optional(),
    autoComplete: z.string().optional(),
    user: z
        .object({
            sub: z.string(),
            user_type: z.string(),
            accessToken: z.string(),
        })
        .partial()
        .optional(),
    renewal: z.boolean().optional(),
    contract: z.string().optional(),
    stickers: z.string().array().optional(),
    type: z.nativeEnum(WallType),
    pageNumber: z.number().optional(),
    limit: z.number().optional(),
});

const appConfig = getAppConfig();

export const getApiProducts = async (
    type: WallType,
    options: GetProductOptionType,
    session?: Session | null,
): Promise<{ products: Product[]; count: number }> => {
    let renewalData:
        | {
              isPremium: boolean;
              contract: ContractType;
              plan: string;
          }
        | undefined;

    if (options?.renewal && session?.user) {
        const contractData = await getContract(session.user as UserType, options.contract as ContractType['id']);
        if (contractData) {
            renewalData = {
                isPremium: contractData.subscription.isPremium,
                contract: contractData,
                plan: contractData.subscription.offerId,
            };
        }
    }

    // @TODO: remove WallType or map ?
    options.category = type === WallType.PHONE ? SapicCategoryId.PHONE : SapicCategoryId.ACCESSORY;

    const currentPlan = renewalData?.plan ?? options?.plan;

    const virtualCart: SimulatorCart = {
        cart: {
            quotes: [
                {
                    isCurrent: true,
                    type: options?.renewal ? QUOTE_TYPES.RENEWAL : QUOTE_TYPES.ACQUISITION,
                    products: [
                        {
                            gencode: (currentPlan ?? getDefaultPlan(renewalData)).toString(),
                        },
                    ],
                    contractId: options?.contract,
                },
            ],
        },
    };

    try {
        const palpatinePromise =
            type === WallType.PHONE
                ? httpService.get<{ items: WallMarketing[] }>(
                      `${appConfig.assets.palpatine.product}/anim_fiche_produit`,
                      undefined,
                      {
                          ttl: parseInt(appConfig.cache.ttl.cms),
                          tags: [CACHE_TAGS.CMS],
                      },
                  )
                : Promise.resolve({ items: [] });

        const [palpatineResult, sapicResult] = await Promise.allSettled([
            palpatinePromise,
            sapicRepository.getProducts(virtualCart, options, session),
        ]);

        if (sapicResult.status === 'rejected') {
            return { products: [], count: 0 };
        }

        const marketingData: Record<string, WallMarketingItem | undefined> = {};
        if (palpatineResult.status === 'fulfilled') {
            palpatineResult.value.items.forEach(({ Slug, highlights }) => {
                marketingData[Slug] = highlights ?? undefined;
            });
        }

        const res = sapicResult.value;

        const count = res.count;

        const products: Product[] = res.products.map((product) => {
            const marketingDataMapped = marketingData[product.urlKey];

            let stickers: Product['stickers'];
            if (type === WallType.PHONE) {
                stickers =
                    marketingDataMapped?.stickers?.items?.map((sticker) => ({
                        label: sticker.sticker_text,
                        color: sticker.sticker_color as VariantState,
                    })) ?? [];
            } else {
                stickers = product.stickers;
            }

            return {
                ...product,
                stickers,
                stamp: marketingDataMapped?.stamp?.public_url
                    ? {
                          url: marketingDataMapped.stamp.public_url,
                          alt: marketingDataMapped.stamp_alt ?? '',
                      }
                    : undefined,
                tag: marketingDataMapped?.label_price
                    ? {
                          amount: marketingDataMapped.label_price,
                          symbol: (marketingDataMapped.label_price_exponent ?? '%') as TagSymbol,
                      }
                    : undefined,
            };
        });

        return { products, count };
    } catch (error) {
        return { products: [], count: 0 };
    }
};

export async function getApiProductDetails(url: string): Promise<SapiProductType> {
    return sapiRepository
        .getProductByUrl<SapiProductType>(url, true)
        .then((productData) => {
            if ('childs' in productData) {
                return productData;
            } else {
                // Transforme un child en Parent
                // Nécessaire pour transformer un ProductType.ACCESSORY en parent
                return transformChildProductToParentProduct(productData);
            }
        })
        .catch((error) => {
            console.error('Could not fetch product details', error);
            throw new Error(`Could not fetch product details`);
        });
}

export async function getApiProductDetailsByGencode(gencode: string): Promise<SapiProductType> {
    return apiHttpService.get<SapiProductType>(`${appConfig.sapi.url}/ventes/produits/${gencode}`);
}

export async function mergeSapicToSapiProduct(
    sapiProduct: SapiProductType,
    session: Session | null,
    isPro = false,
): Promise<SapiMergedSapicType> {
    const gencodeList = [sapiProduct.gencode, ...sapiProduct.product_ids];

    const params: GetProductOptionType = {
        limit: 50,
        isPro,
        gencodes: gencodeList,
    };

    const virtualCart: SimulatorCart = {
        cart: {
            quotes: [
                {
                    type: QUOTE_TYPES.ACQUISITION,
                    isCurrent: true,
                    products: [],
                },
            ],
        },
    };

    return await sapicRepository
        .getProducts(virtualCart, params, session)
        .then(({ products }) => {
            const childsWithSapicList: SapiProductChildTypeWithSapicInfo[] = Object.values(sapiProduct.childs).map(
                (child) => ({
                    ...child,
                    productSapic: products.find((sapicChild) => sapicChild.gencode === child.gencode),
                }),
            );
            return {
                ...sapiProduct,
                productSapic: products[0],
                childs: Object.fromEntries(childsWithSapicList.map((child) => [child.gencode, child])),
            };
        })
        .catch((error) => {
            console.error('Could not fetch produits-contextualises', error);
            throw new Error(`Could not fetch produits-contextualises`);
        });
}

export async function getWallProducts(
    type: WallType = WallType.PHONE,
    options?: GetProductOptionType,
    session?: Session | null,
) {
    const searchParams = { type, ...options };
    const params = GetWallProductApiSchema.parse(searchParams);

    try {
        const productsResult = await getApiProducts(type, params, session);

        const gencodes = productsResult.products.map((product) => {
            return product.gencode;
        });

        if (gencodes.length > 0) {
            const averages = await reviewServices.getAverageReviews(gencodes);

            if (averages) {
                productsResult.products.forEach((product) => {
                    const averageProduct = averages.find((average) => average.gencode === product.gencode);
                    if (averageProduct) {
                        product.rating = {
                            count: averageProduct.count,
                            value: averageProduct.rate,
                        };
                    }
                });
            }
        }

        return productsResult;
    } catch (error) {
        return { products: [], count: 0 };
    }
}

export async function getFaiPlan(
    virtualCart: SimulatorCart,
    options: GetProductOptionType,
    session: Session | null = null,
): Promise<FaiType[]> {
    const result = await sapicRepository.getFaiPlans(virtualCart, options, session);
    return result.products;
}

export async function getSapiProductsByGencode(gencodes: string[]) {
    return await sapiRepository.getProductsByGencodes<SapiResponseType<SapiProductType>>(gencodes);
}
