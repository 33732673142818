import { FetchService } from '@services/http/fetch-service';

import { axiosInstanceFactory } from './axios-instance-factory';
import { AxiosService } from './axios-service';
import { HttpService } from './http';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const httpService: FetchService = new FetchService(false);
export const externalHttpService: HttpService = new AxiosService(axiosInstanceFactory(true));

export const apiHttpService: FetchService = new FetchService(true);
export const cmsHttpService: FetchService = new FetchService(false, true);
