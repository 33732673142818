import { appContext } from '@services/app-process';
import { getAppConfig } from '@services/config';
import { fetchLoggerService } from '@services/loggers/fetch-logger.service';

import { isServer } from '@helpers/config';

import { API_X_SOURCE, API_X_VERSION } from '@constants/api';

import { v4 as uuid } from 'uuid';

import { HttpConfig, HttpService } from './http';

const appConfig = getAppConfig();

export class FetchService extends HttpService {
    private readonly isApi: boolean;
    private readonly isCms: boolean;
    private headers: Headers = new Headers();

    constructor(isApi = false, isCms = false) {
        super();

        this.isApi = isApi;
        this.isCms = isCms;
    }

    public setInitialHeaders(headers: Headers): void {
        this.headers = headers;
    }

    public async get<T>(url: string, data?: Record<string, string>, httpConfig: HttpConfig = {}): Promise<T> {
        const queryParams = new URLSearchParams(data).toString();

        if (queryParams) {
            url += `?${queryParams}`;
        }

        const request = new Request(url, {
            method: 'GET',
        });

        return this.launchRequest<T>(request, this.initConfig(httpConfig));
    }

    public async post<T>(url: string, data: object, httpConfig: HttpConfig = {}): Promise<T> {
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: httpConfig.headers,
        });

        return this.launchRequest<T>(request, this.initConfig(httpConfig));
    }

    protected async launchRequest<T>(request: Request, config: RequestInit): Promise<T> {
        const start = Date.now();

        const duplicateRequest = new Request(request.clone(), { headers: config.headers });

        return fetch(request, config)
            .then(async (res) => {
                // replace potential interceptors
                if (!this.isCms) {
                    await fetchLoggerService.logHttpResponse(res, duplicateRequest, start);
                }
                return this.processResponse<T>(res);
            })
            .catch(async (err) => {
                if (!this.isCms) {
                    await fetchLoggerService.logHttpError(err, duplicateRequest, start);
                }
                throw new Error(err);
            });
    }

    protected async processResponse<T>(response: Response): Promise<T> {
        if (!response.ok) {
            const text = await response.clone().text();
            throw new Error(`Network response was not ok : ${text}`);
        }

        if (this.isCms) {
            return response.clone().text() as Promise<unknown> as Promise<T>;
        }

        return response.clone().json();
    }

    protected initConfig(httpConfig: HttpConfig): RequestInit {
        let headers = httpConfig && httpConfig.headers ? new Headers(httpConfig.headers) : new Headers();

        const config: RequestInit = {
            signal: httpConfig?.controller ? httpConfig.controller.signal : undefined,
        };

        if (httpConfig.user) {
            config.next = {
                revalidate: 5 * 60,
            };
        } else if (httpConfig?.ttl !== undefined) {
            if (httpConfig.ttl === 0) {
                config.cache = 'no-cache';
            } else {
                config.next = {
                    // ttl in minutes (revalidate = seconds)
                    revalidate: httpConfig.ttl * 60,
                };
            }
        }

        if (httpConfig?.tags && httpConfig.tags.length > 0) {
            config.next = {
                ...config.next,
                tags: httpConfig.tags,
            };
        }

        if (!this.isCms) {
            headers.set('content-type', 'application/json');
        }

        headers = this.setHeadersApi(httpConfig, headers);
        headers = this.cleanHeaders(headers);

        return { ...config, headers: headers };
    }

    private setHeadersApi(httpConfig: HttpConfig, baseHeaders: Headers): Headers {
        const headers = new Headers(baseHeaders);
        if (this.isApi) {
            if (!headers.get('x-version')) {
                headers.set('x-version', API_X_VERSION.V4);
            }

            headers.set('x-source', API_X_SOURCE);
            headers.set('x-process', appContext.getProcess());
            headers.set('accept-encoding', 'gzip');

            const clientIp = this.headers.get('x-forwarded-for');
            const trackerId = this.headers.get('x-tracker-id');
            const requestId = this.headers.get('x-request-id');
            if (trackerId) {
                headers.set('x-tracker-id', trackerId);
            }

            if (requestId) {
                headers.set('x-request-id', requestId);
            }

            if (clientIp) {
                headers.set('x-real-ip', clientIp);
                headers.set('x-bytel-ip', clientIp);
            }

            if (appConfig.bench && !headers.get('x-banc')) {
                headers.set('x-banc', appConfig.bench);
            }

            if (httpConfig.user) {
                // const user = httpConfig.user;
                headers.set('authorization', `Bearer ${httpConfig.user.accessToken}`);
                // if (user.login && user.user_type) {
                //     headers.set('login', user.login);
                //     headers.set('userType', user.user_type);
                // }
            }
        } else if (!isServer()) {
            // Add x-request-id header for client side HTTP calls (httpService)
            headers.set('x-request-id', uuid());
        }

        return headers;
    }

    private cleanHeaders(headers: Headers) {
        headers.delete('host');
        headers.delete('accept');
        headers.delete('accept-language');
        headers.delete('connection');
        headers.delete('content-length');
        headers.delete('origin');
        return headers;
    }
}
