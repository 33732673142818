import { Promotion } from '@app-types/api/sapic';
import { CalculatedDiscount } from '@app-types/api/vapi';
import { DiscountEnumType, DiscountType } from '@app-types/discount';

export class ApiDiscountMapper {
    public static mapPromotionSapic(discount: Promotion): DiscountType {
        return {
            type: discount.type, // @TODO: create specific type and mapper
            value: discount.valeur,
            discount: discount.reduction,
            isIncitation: discount.estIncitation,
            isDiffered: discount.estDifferee,
            duration: discount.duree,
            marketingTypes: discount.proprietesSupplementaires?.typesMarketing ?? [],
            pennant: discount.proprietesSupplementaires?.fanion,
        };
    }

    public static mapPromotionVapi(discount: CalculatedDiscount): DiscountType {
        return {
            type: ApiDiscountMapper.getPromoTypeFromVapi(discount),
            value: discount.remiseCalculee.remise.montant,
            discount: discount.remiseCalculee.remise.montant,
            duration: discount.remiseCalculee.remise.duree,
            marketingTypes: [],
            // @TODO: complete with other fields
        };
    }

    public static getPromoTypeFromVapi(discount: CalculatedDiscount): DiscountEnumType {
        switch (true) {
            case discount.type === 'MONTANT_VARIABLE':
                return DiscountEnumType.VARIABLE_AMOUNT;
            case discount.type === 'PROMOTION_COUPON':
                return DiscountEnumType.COUPON;
            case discount.type === 'PROMOTION_STANDARD':
            default:
                return DiscountEnumType.STANDARD;
        }
    }
}
