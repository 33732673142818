export enum RecoveryStatus {
    COMPLETED = 'completed',
    UNFINISHED = 'unfinished',
}

export enum RecoveryStep {
    SEARCH = 1,
    FORM = 2,
    CONFIRMATION = 3,
    CONFIRMED = 4,
    NOT_STARTED = 5,
    CHOICE_NO = 6,
}
