import { getAppConfig } from '@services/config';
import { apiHttpService } from '@services/http';
import { logger } from '@services/loggers';

import { sapiRepository } from '@repositories/sapi';

import { ApiContractType } from '@app-types/api/contract';
import { SapiProductType, SapiResponseType } from '@app-types/api/sapi';
import { ContractType } from '@app-types/contract';
import { UserType } from '@app-types/user';

import { PlanTypeId } from '@constants/plan';

class ContractsRepository {
    url: string;

    public constructor(url: string) {
        this.url = url;
    }

    public async getSignedContracts(sub: string, user: UserType): Promise<ContractType[]> {
        const { items } = await apiHttpService
            .get<{
                items: ApiContractType[];
            }>(`${this.url}/personnes/${sub}/contrats-signes`, undefined, {
                user,
            })
            .catch((error) => {
                throw new Error(`Could not fetch signed contracts`, error);
            });

        const filteredItems = items.filter((offer) => offer.typeLigne === 'MOBILE');

        const { produits: offersDetails } = await this.getOffersDetails(
            filteredItems.map((contract) => contract.abonnement.idOffre),
            user,
        );

        return Promise.all(
            filteredItems.map(async (contract) => {
                const [contractDetails, isRenewalEligible] = await Promise.all([
                    this.getContractDetails(contract, user),
                    this.getRenewalEligibility(contract.id, user),
                ]);
                const { iban, mobileEquipment } = contractDetails;
                const product = offersDetails[contract.abonnement.idOffre];

                return {
                    subscription: {
                        activationDate: contract.abonnement.dateActivation,
                        offerId: contract.abonnement.idOffre,
                        label: contract.abonnement.libelle,
                        mobileDataOffer: contract.abonnement.offreDataMobile,
                        isPremium: product?.type_id ? /plan_(premium|ideo)/.test(product.type_id) : false,
                        isSowo: product?.type_id ? product.type_id.includes(PlanTypeId.SOWO) : false,
                    },
                    statusChangeDate: contract.dateChangementStatut,
                    creationDate: contract.dateCreation,
                    id: contract.id,
                    marketLine: contract.ligneMarche,
                    phoneNumber: contract.numeroTel,
                    status: contract.statut,
                    lineType: contract.typeLigne,
                    user: {
                        rightsProfileLabel: contract.utilisateur.libelleProfilDroits,
                        rightsProfile: contract.utilisateur.profilDroits,
                    },
                    isRenewalEligible,
                    iban,
                    mobile: mobileEquipment,
                };
            }),
        );
    }

    public async getContract(sub: string, contractId: string, user: UserType): Promise<ContractType | undefined> {
        return this.getSignedContracts(sub, user).then((contracts) => {
            return contracts.find((contract) => contract.id === contractId);
        });
    }

    public async getPersonneFromContract(idContract: string, user: UserType): Promise<string> {
        try {
            const contract = await apiHttpService.get<{ _links: { titulaire: { href: string } } }>(
                `${this.url}/contrats/${idContract}`,
                undefined,
                {
                    user,
                },
            );

            return contract._links.titulaire.href.replace('/personnes/', '');
        } catch (e) {
            logger.error(`Error while fetching personne from contract "${idContract}"`, e);
            throw new Error(`Could not fetch personne from contract "${idContract}"`);
        }
    }

    private async getRenewalEligibility(contractId: string, user: UserType): Promise<boolean> {
        try {
            const { items } = await apiHttpService.get<{ items: { statut: boolean; renouvellementAutorise: true }[] }>(
                `${this.url}/contrats/${contractId}/eligibilites-renouvellement`,
                undefined,
                {
                    user,
                },
            );

            const contract = items?.[0];

            if (!contract) {
                return false;
            }

            return contract.statut && contract.renouvellementAutorise;
        } catch (error) {
            return false;
        }
    }

    private async getContractDetails(contract: ApiContractType, user: UserType) {
        const ibanPromise = this.getIban(contract._links.compteFacturation.href, user);
        const mobileEquipmentsPromise = this.getMobileEquipments(contract.id, user);

        return Promise.allSettled([ibanPromise, mobileEquipmentsPromise]).then(([iban, mobileEquipment]) => {
            if (iban.status === 'rejected') {
                throw iban.reason;
            }

            return {
                iban: iban.value,
                mobileEquipment: mobileEquipment.status === 'fulfilled' ? mobileEquipment.value : null,
            };
        });
    }

    private async getIban(url: string, user: UserType): Promise<string | undefined> {
        const data = await apiHttpService
            .get<{
                compteBancaire?: {
                    iban: string;
                };
            }>(`${this.url}${url}`, undefined, {
                user,
            })
            .catch((error) => {
                throw new Error(`Could not fetch iban`, error);
            });

        return data.compteBancaire?.iban;
    }

    private async getMobileEquipments(contractId: string, user: UserType) {
        const data = await apiHttpService
            .get<{
                cartesSim: {
                    dateRemise: string;
                    iccid: string;
                }[];
                conditionsRenouvellement: {
                    dateEligibilitePrevisionnelleRenouvellement: string;
                    dateTarifPreferentiel: string;
                    renouvellementAutorise: boolean;
                    eligibleRenouvellement: boolean;
                    eligibleEtalementPaiement: boolean;
                    migrationObligatoire: boolean;
                };
                mobilesContractuels?: {
                    imei: string;
                    genCode?: string;
                }[];
                mobileUsage?: {
                    codeTac: string;
                    genCode?: string;
                    imei: string;
                };
            }>(`${this.url}/contrats/${contractId}/equipements-mobiles`, undefined, {
                user,
            })
            .catch((error) => {
                throw new Error(`Could not fetch mobile equipments`, error);
            });

        const gencode = data?.mobileUsage?.genCode || data?.mobilesContractuels?.[0]?.genCode;

        let mobile = null;

        if (gencode) {
            try {
                mobile = await sapiRepository.getProductByGencode<SapiProductType>(gencode);
            } catch (error) {
                throw new Error(`Could not fetch mobile equipments data`);
            }
        }

        return mobile ? { gencode: mobile.gencode, brand: mobile.manufacturer, name: mobile.name } : null;
    }

    private async getOffersDetails(offers: string[], user: UserType): Promise<SapiResponseType<SapiProductType>> {
        try {
            if (offers.length > 0) {
                return await sapiRepository.getProductsByGencodes<SapiResponseType<SapiProductType>>(offers, user);
            } else {
                return { produits: {} };
            }
        } catch (error) {
            logger.error(error);
            return { produits: {} };
        }
    }
}

export const contractsRepository = new ContractsRepository(getAppConfig().b2r.url);
