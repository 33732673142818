import { ParcoursVirtuel } from '@app-types/api/vapi';
import { QUOTE_TYPES } from '@app-types/cart';
import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType } from '@app-types/funding';
import { ObligationType } from '@app-types/plan';
import { ProductType } from '@app-types/product';

import { PLAY, TECHNOLOGY } from '@constants/provider';

/* eslint-disable @typescript-eslint/naming-convention */
export enum SapicConnectivity {
    '5G' = '5g',
    '4G' = '4g',
    '4G+' = '4g+',
}

export type SapicProductTypeV4 = {
    gencode: string;
    url: string;
    marque?: string;
    nom: string;
    prix: {
        initial: number;
        subventionne: number;
        pourToujours: number;
        final: number;
    };
    capacite?: string;
    estEnStock: boolean;
    image: string;
    type: ProductType;
    note: number;
    connectivite?: SapicConnectivity;
    etiquetteAnimCo?: string;
    dispoCnc?: boolean;
    recommande?: boolean;
    promotions: Promotion[];
    financements: SapicFundingType[];
    couleurs?: string[] | null;
    typeSim: string;
    excluPro: boolean;
    dureeEngagement?: ObligationType;
    estNonVendableAcquisition: boolean;
    estNonVendableRenouvellement: boolean;
    medias: string[];
    categories: string[];
    quantite: number;
    panierSimule?: SimulatedCartV4;
    gamme?: string;
    classification?: string;
    libelleEnveloppeData?: string;
    libelleEnveloppeVoix?: string;
    technologie?: TECHNOLOGY;
};

export type SimulatedCartV4 = {
    parcours: SimulatedCartQuoteV4[];
    promotions: Promotion[];
    financements: SapicFundingType[];
};

export type SimulatedCartQuoteV4 = {
    estCourant: boolean;
    produits: SimulatedCartProductV4[];
};

export type SimulatedCartProductV4 = {
    gencode: string;
    type: string;
    prix: {
        initial: number;
        pourToujours: number;
        final: number;
        subventionne: number;
    };
    typePrix: string;
    promotions: Promotion[];
    financements: SapicFundingType[];
    marque?: string;
};

export type SapicFundingType = {
    type: FundingEnumType;
    prefere: boolean;
    apportInitial: number;
    nbrEcheances: number;
    montantMensualite: number;
    tAEG: number;
    montantInteret: number;
    coutTotalFinancement: number;
    montantAFinancer: number;
    tauxDebiteurFixe?: number;
};

export enum SapicCustomerCategory {
    GP = 'GP',
    PRO = 'PRO',
}

export enum SapicFundingMode {
    ALL = 'tous',
    AUTO = 'auto',
    MIN_ONE_OFF = 'min-one-off',
    NONE = 'aucun',
}

export enum SapicPriceForFilter {
    INITIAL = 'initial',
}

export enum SapicPriceForSort {
    INITIAL = 'initial',
}

export enum SapicCategoryId {
    PHONE = 'phone',
    ACCESSORY = 'accessory',
    PREMIUM = 'plan_premium',
    PREMIUM_PRO = 'plan_pro_premium',
    PREMIUM_RENEWAL = 'plan_premium_renew',
    SIMO = 'plan_simo',
    SIMO_PRO = 'plan_pro_simo',
    SOWO = 'plan_sowo',
    SOWO_RENEWAL = 'plan_sowo_renew',
    SOWO_SPECIALS = 'plan_sowo_specials',
    IDEO = 'plan_ideo',
    IDEO_PRO = 'plan_ideo_pro',
    FAIM = 'faim',
    FAI = 'fai',
    FAIM_UNLIMITED = 'faim_unlimited',
    FAMILY = 'family-web',
}

export type SapicProductPayloadV4 = {
    boutique: 'web';
    categorie?: SapicCategoryId;
    // type for all
    clientCategorie: SapicCustomerCategory;
    modePourFinancement: SapicFundingMode;
    prixReferencePourFiltre?: SapicPriceForFilter;
    tri?: string;
    limite: string;
    decalage?: string;
    prixReferencePourTri?: SapicPriceForSort;
    prix?: string;
    typeParcours?: string;
    forfait?: string;
    nom?: string;
    detail?: string;
    gencodes?: string;
};

export type SapicProductPayloadV5 = SapicProductPayloadV4 & {
    gencodes?: string;
    filtre?: string;
};

export type SapicResponseTypeV4 = {
    produits: SapicProductTypeV4[];
    initiateur: string;
    nombreTotalProduits: number;
};
// @TODO: implement this type
export type SapicContextualisedProductPayload = {
    panier: {
        type: string;
        catalogue: 'WEB';
    };
};
// V5 Sapic Product
export type SapicResponseTypeV5 = {
    produits: SapicProductTypeV5[];
    initiateur: string;
    nombreTotalProduits: number;
};

export type SapicProductTypeV5 = SapicProductTypeV4 & {
    dasTete?: string;
    dasTronc?: string;
    dasMembre?: string;
    indiceReparabilite?: string;
    autonomie?: string;
    migrationPossible?: boolean;
    estCompatibleRenouvellement: boolean;
    estEnStock?: boolean;
    promotionsApplicables?: Promotion[];
    fraisDeLocation?: number;
    niveauGammeConvergent?: string;
    fraisDeMiseEnService?: Frais;
    fraisMiseADisposition?: Frais;
    debitDescendant?: string;
    debitAscendant?: string;
    produitPrioritaire?: string;
    typeAccessoire?: string[];
    gammeCommerciale: string;
    systemeExploitation?: string;
    telephoneAvec?: string[];
    etat?: string;
    blocage?: string;
    eligibilites?: string[];
    ajoutsAutomatiquesFront?: string[];
    classification?: string;
    jeu?: PLAY;
    typeCategorieFai?: string;
    panierSimule?: SimulatedCartV5;
};

export type SimulatedCartV5 = {
    parcours: ParcoursVirtuel[];
    catalogue: string;
};

export enum SapicRange {
    BBOX_ULTYM = 'Bbox ultym',
    BBOX_MUST = 'Bbox must',
    BBOX_FIT = 'Bbox fit',
}

export type Frais = {
    prix: number;
    typePrix: string;
};

export type Promotion = {
    type: DiscountEnumType;
    valeur: number;
    reduction: number;
    estIncitation: boolean;
    estDifferee: boolean;
    duree: number;
    proprietesSupplementaires: {
        fanion?: string;
        typesMarketing: string[];
    };
    typesAccess: string[];
};

export type Financement = {
    type: string;
    prefere: boolean;
    apportInitial: number;
    nbrEcheances: number;
    montantMensualite: number;
    tAEG: number;
    tauxDebiteurFixe: number;
    montantInteret: number;
    coutTotalFinancement: number;
    montantAFinancer: number;
};

export type RemiseCalculee = {
    montant: number;
    remise: Remise;
};

export type Remise = {
    duree: number;
    objetCatalogue: {
        identifiant: string;
    };
    estSousReserve: boolean;
    type: string;
};

export type Prix = {
    initial: number;
    subventionne: number;
    pourToujours: number;
    final: number;
};

export type SapicContextualisedProductV4Payload = {
    panier: {
        parcours: {
            type: QUOTE_TYPES;
            estCourant: boolean;
            produits: { gencode: string }[];
            idContrat?: string;
        }[];
        acheteur?: {
            type: 'GP' | 'PRO';
            noPersonne?: string;
        };
    };
};

export type SapicContextualisedProductV5Payload = {
    referenceExterneParcoursCible: string;
    panier: {
        catalogue: 'WEB';
        acheteur?: {
            type: 'NON_CONNU_GP' | 'PERSONNE' | 'NON_CONNU_PRO';
            noPersonne?: string;
        };
        parcours: {
            referenceExterne: string;
            typeParcours: QUOTE_TYPES;
            statut: 'EN_COURS' | 'TERMINE';
            elements: {
                referenceExterne: string;
                composantCommercial: {
                    identifiant: string;
                    typeCatalogue: 'BYTEL';
                };
            }[];
        }[];
    };
};
