import { FundingType } from '@app-types/funding';

import { PlanRange, PlanTypeId } from '@constants/plan';
import { PLAY, RANGE, TECHNOLOGY } from '@constants/provider';

import { LockedPlanValue } from 'src/app/(offer-wall)/_helpers/plan-lock';

import { Product } from './product';
import { GalliumOffreApi } from './renewal';

export enum PlanProvenanceType {
    DEFAULT = 'default',
    CART = 'cart',
    URL = 'url',
}

export enum ObligationType {
    MONTHLY_12 = 'monthly12',
    MONTHLY_24 = 'monthly24',
    NONE = 'none',
}

export type BasePlanType = Product & {
    obligation?: ObligationType;
    obligationLabel?: string;
    commercialRange?: PlanRange;
    range: string;
    downRates: string;
    upRates: string;
};

export type PlanType = BasePlanType & {
    dataEnvelope?: string;
    dataSize?: number;
    fromAbroad?: string;
    nuggets?: Array<PlanNugget>;
    fundings: FundingType[];
    gencode: string;
    sku?: string;
    hasDoubleSim: boolean;
    hasLowPrice: boolean;
    internationalCall?: string;
    isDarwin: boolean;
    price: number;
    range: string;
    smsMms?: string;
    technology?: TECHNOLOGY;
    typeId: PlanTypeId;
    urlKey?: string;
    detailsUrl?: string;
    popInCms?: string;
    isOwned?: boolean;
    detailsDescription?: string;
    planFlag?: string;
    locked?: LockedPlanValue;
    maxConnections?: string;
    entityId?: string;
    specialPriceRenewal?: {
        discountAmount?: number;
        finalTTCAmount?: number;
    };
    rentalFees?: number;
    subtitle?: string;
    fanion?: string;
    options?: Array<PlanOptions>;
    isDefault?: boolean;
    renewalDiscounts?: GalliumOffreApi[];
    renewalLabelDataEnvelope?: string;
};

export type FaiType = BasePlanType & {
    rentalFees: number;
    downRates: string;
    upRates: string;
    technology: TECHNOLOGY;
    play: PLAY;
    categoryType: string;
    range: RANGE;
};

export type PlanDetailWithProvenanceType = Partial<PlanType> & {
    origin: PlanProvenanceType;
    gencode: string;
};

export type CategoryListType = CategoryPlanType[];

export type CategoryPlanType = {
    id: string;
    name: string;
    description: string | null;
    code: string;
    subtitle: string;
    url: string;
    products: PlanType[];
    _links: {
        detailCategory: {
            href: string;
        };
    };
};

export enum RangeType {
    AVEC_MOBILE = 'forfait_avec_mobile',
    SANS_MOBILE = 'forfait_sans_mobile',
}
export type PlanNugget = {
    icon: string;
    label: string;
    description?: string;
    position?: number;
    showSpeed?: boolean;
};

export type PlanOptions = {
    logo_url: string;
    logo_alt: string;
    label: string;
    position: number;
};

export interface PlanTypeWithEligibility extends PlanType {
    isEligible?: boolean;
}

export interface PlansWithEligibilityObject {
    [key: string]: PlanTypeWithEligibility;
}
