export enum PlanTypeId {
    DEFAULT = 'default',
    PREMIUM = 'plan_premium',
    PREMIUM_SELECTION = 'plan_premium_selection',
    PREMIUM_PRO = 'plan_pro_premium',
    PREMIUM_RENEWAL = 'plan_premium_renew',
    PREMIUM_ENTRY = 'plan_premium_entry',
    PREMIUM_MULTILIGNE = 'plan_premium_multiligne',
    SIMO = 'plan_simo',
    SIMO_SELECTION = 'plan_simo_selection',
    SIMO_ENTRY = 'plan_simo_entry',
    SIMO_MULTILIGNE = 'plan_simo_multiligne',
    SIMO_PRO = 'plan_pro_simo',
    SOWO = 'plan_sowo',
    SOWO_PRO = 'plan_pro_sowo',
    SOWO_RENEWAL = 'plan_sowo_renew',
    SOWO_SPECIALS = 'plan_sowo_specials',
    IDEO = 'plan_ideo',
    IDEO_PRO = 'plan_ideo_pro',
    FAIM = 'faim',
    PREMIUM_PRO_SELECTION = 'plan_pro_premium_selection',
    SIMO_PRO_SELECTION = 'plan_pro_simo_selection',
    FAI = 'fai',
}

export enum MarketingType {
    CONVERGENCE = 'CONVERGENCE', // @deprecated Use MarketingType.HOME instead since Toutatis
    MULTILINE = 'MULTILIGNES',
    PORTABILITY = 'PORTABILITE',
    ZIP_CODE_FIXED_ELIGIBILITY = 'CODE_POSTAL_ELIGIBILITE_FIXE',
    SALES = 'SOLDES',
    HOME = 'FOYER',
    MOBILE = 'MOBILE',
}

export enum PlanRange {
    DEFAULT = '',
    B_YOU = 'b_you',
    WITH_MOBILE = 'forfait_avec_mobile',
    WITHOUT_MOBILE = 'forfait_sans_mobile',
    BBOX_ULTYM = 'large',
    BBOX_MUST = 'medium',
    BBOX_FIT = 'small',
}
