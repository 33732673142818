export enum FundingEnumType {
    EDP = 'EDP',
    COMPTANT = 'COMPTANT',
    YOUNITED = 'YOUNITED',
    YOUNITED_X3 = 'YOUNITED_X3',
    YOUNITED_X12 = 'YOUNITED_X12',
    YOUNITED_X36 = 'YOUNITED_X36',
    YOUNITED_X24 = 'YOUNITED_X24',
    COFIDIS = 'COFIDIS',
    COFIDIS_X12 = 'COFIDIS_X12',
    COFIDIS_X24 = 'COFIDIS_X24',
    COFIDIS_X36 = 'COFIDIS_X36',
}

export type FundingType = {
    type: FundingEnumType;
    favorite: boolean;
    label: string;
    initialDeposit: number;
    installmentCount: number;
    monthlyAmount: number;
    APR: number;
    interestAmount: number;
    totalFinancingCost: number;
    amountToFinance: number;
    fixedInterestRate?: number;
    initialContributionWithOdr?: number;
};
