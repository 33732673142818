export const roundStringNumber = (
    num: string | undefined,
    scale: number,
    keepsDecimal0: boolean = false,
): string | undefined => {
    if (!num) {
        return num;
    }
    const numFloat = parseFloat(num);

    let result = numFloat.toFixed(scale);
    if (!keepsDecimal0) {
        //supprime les 0 après la virgule
        result = parseFloat(result).toString();
    }
    return result.replace('.', ',');
};

export const stringToNumber = (num: string): number => {
    return parseFloat(num.replace(',', '.'));
};

export const numberToString = (num: number): string => {
    return num.toString().replace('.', ',');
};
